import React from "react";

function features() {
  return (
    <>
      <section className=" z-20 relative">
        <div className="hidden lg:block connecting-lines">
          <div className="line horizontal-line"></div>
          <div className="line vertical-line"></div>
          <div className="line vertical1-line"></div>
        </div>
        <div className="md:grid md:grid-cols-2 gap-5 ">
          <button className="px-7 bg-main w-full mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Automated Trading
          </button>
          <button className="px-7 w-full bg-main  mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Backtesting
          </button>
          <button className="px-7 w-full bg-main  mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Crypto Portfolio
          </button>
          <button className="px-7 w-full bg-main  mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Fully-Automated Bots
          </button>
          <button className="px-7 w-full bg-main  mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Semi-Automated Bots
          </button>
          <button className="px-7 w-full bg-main  mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Stop-Loss
          </button>
          <button className="px-7 w-full bg-main  mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Arbitrage Trading
          </button>
          <button className="px-7 w-full bg-main  mt-4 md:mt-0 py-5 mx-2 rounded-2xl font-bold text-[white] border border-[white] border-b-8">
            Push Notifications
          </button>
        </div>
      </section>
    </>
  );
}

export default features;
