import React from 'react'

const PaginationContact = ( { totalposts, postperpage, paginate } ) => {

    const pagenumbers = [];

    for ( let i = 1; i <= Math.ceil( totalposts / postperpage ); i++ ) {
        pagenumbers.push(i);
    }

  return (
    <>
        <div className='container mx-auto block mt-6'>
            <nav className='w-full'>
                <ul className='flex ml-auto justify-end'>
                    {
                        pagenumbers && pagenumbers.map( num => {
                            return (
                                <li key={ num }>
                                    <p className='py-2 hover:cursor-pointer px-4 bg-[#000] text-second rounded-lg mx-1 hover:text-[#fff]' onClick={ () => paginate(num) } > { num } </p>
                                </li>
                            )
                        } )
                    }
                </ul>
            </nav>
        </div>
    </>
  )
}

export default PaginationContact;