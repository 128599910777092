import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from './Pagination';

const ResumeDash = () => {

  const [response, setResponse] = useState([]);

  const [currentpage, setcurrentpage] = useState(1);
  const [postperpage] = useState(10);
   const [activePage, setActivePage] = useState(1); 

//   current posts

  const sortedData = response.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

    const indexoflastpost = currentpage * postperpage;
    const indexoffirstpost = indexoflastpost - postperpage;
    const currentres = sortedData.slice(indexoffirstpost,indexoflastpost);

  // const fetchfunction = async () => {
  //   const res = await axios
  //   .get('https://api.earnestroi.com/api/resume')
  //   .catch( err => console.log(err) )
  //   setResponse(res.data.data);
  // }


  const fetchfunction = async () => {
    try {
      const JWT = localStorage.getItem("JWT");

      const formData = {
        jwt: JWT, // Change "JWT" to "jwt"
      };

      const response = await axios.post(
        "https://dapib.stashack.cloud:2500/private/view_career",
        formData
      );

      const responseData = response?.data?.Success;
      console.log("Response:", responseData);
        setResponse(responseData);
      // Handle the response data as needed
      // For example, update state with the data
      // setState(responseData);
    } catch (error) {
      console.error("Error:", error);

      if (error.response) {
        console.log("Response Data:", error.response.data);

        // Handle specific error cases based on the response data
        // For example, show an error message to the user
        // toast.error(error.response.data.Error);
      }
    }
  };

  useEffect(() => {
    fetchfunction()
  }, []);

  
  const paginate = (pgno) => {
    setcurrentpage(pgno);
    setActivePage(pgno); // Set the active page when pagination is clicked
  };

  

  return (
    <>
      <section className="w-full">
        <div className="container mx-auto mt-12 px-2 sm:px-4 mb-6">
          <div className="container mx-auto">
            <h2 className="text-second font_primary text-center text-6xl">
              Hiring Resumes
            </h2>
          </div>
          <Table className="mt-10">
            <Thead className="text-third font_primary">
              <Tr>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal rounded-tl-xl">
                  Name
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Emails
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Phone
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Role
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Date
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Current CTC
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Expected CTC
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Resume
                </Th>
              </Tr>
            </Thead>
            <Tbody className="border-[1px] border-second">
              {currentres &&
                currentres?.map((resp) => {
                  const {
                    FormID,
                    Name,
                    Email,
                    Phone_Number,
                    Role,
                    Current_CTC,
                    Expected_CTC,
                    Resume_URL,
                    DateTime,
                  } = resp;

                  const date = new Date(DateTime);
                  const daten = date.toLocaleDateString("en-GB");

                  return (
                    <Tr key={FormID}>
                      <Td className="border-[1px] p-1 border-second">{Name}</Td>
                      <Td className="border-[1px] p-1 border-second">
                        {Email}
                      </Td>
                      
                      <Td className="border-[1px] p-1 border-second">
                        {Phone_Number}
                      </Td>
                      <Td className="border-[1px] p-1 border-second">{Role}</Td>

                      <Td className="border-[1px] p-1 border-second">
                        {DateTime}
                      </Td>
                      <Td className="border-[1px] p-1 border-second">
                        {Current_CTC}
                      </Td>
                      <Td className="border-[1px] p-1 border-second">
                        {Expected_CTC}
                      </Td>
                      <Td className="border-[1px] p-1 border-second text-third underline">
                        {
                          <a
                            href={`{Resume_URL}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Resume
                          </a>
                        }
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </div>
        <Pagination
          postperpage={postperpage}
          totalposts={response?.length}
          paginate={paginate}
          currentPage={currentpage}
        />
      </section>
    </>
  );
}

export default ResumeDash;