import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../img/earnestroi-logo-60x60.png';
import { toast } from 'react-toastify';

const Loginpage = () => {

    const [data, setData] = useState({
        email:"",
        password:""
    });
      const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

   const submitform = async (e) => {
     e.preventDefault();
     setLoading(true);

     try {
       const response = await axios.post(
         "https://dapib.stashack.cloud:2500/private/login",
         data
       );

       const res = response?.data;
       if (res.Success == "Logged in successfully!") {
         toast.success(res.Success);
         localStorage.setItem("JWT", response.data.JWT);
         window.location.href = "/admin/blogs";
        //  if (res.Success === 200) {
        //    navigate("/admin/blogs");
        //  }
       } else {
         toast.error(res.Error);
       }
     } catch (error) {
       console.error(error);
     }

     setLoading(false);
   };

    // const submitform = async (e) => {
    //     e.preventDefault();
    //     await axios
    //     .post('https://api.earnestroi.com/api/login', data)
    //     .then( res => {
    //         // console.log(res.data);
    //         localStorage.setItem('id',JSON.stringify(res.data.id));
    //         localStorage.setItem('access_token',JSON.stringify(res.data.access_token));
    //         if( res.status === 200 ) {
    //             navigate('/admin/blogs');
    //         }
    //         toast.success('Login Successfull');
    //     } )
    //     .catch( err => {
    //         console.log(err.message)
    //         toast.error('Invalid Credentials, Try Again !!!');
    //     } )
    // }

    const handlechange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData( values => ({...values, [name]:value}) )
    }

  return (
    <>
        <section className=' lg:pt-4 xl:pt-0 bg-main px-1 sm:px-0 lg:px-1 xl:px-0 py-20 h-screen'>
            <div className='py-8'>
                <div className='container mx-auto'>
                    <div className='w-full md:w-3/4 pt-16 pb-4 lg:w-1/2 xl:w-1/4 px-4 sm:px-2 border-2 border-second rounded-xl mx-auto'>
                        <img src={ logo } alt='logo earnestroi' className='mx-auto block w-1/4' />
                        <h2 className='text-third font_primary text-center text-5xl my-2'>Sign In</h2>
                        <form className='py-8 px-4' onSubmit={ submitform }>
                            <input type='email' name='email' autoComplete='username' value={ data.email || '' } onChange={ handlechange } className='border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-2' placeholder='Email id' />
                            <input type='password' autoComplete='current-password' name='password' value={ data.password || '' } onChange={ handlechange } className='border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-2' placeholder='Password' />
                            <button type='submit' className='text-base mr-auto lg:mx-auto hover:shadow-[0_0_12px_#FE8067] bg-second px-8 py-2 my-2 hover:border-2 border-second border-2 rounded-lg hover:bg-[black] block hover:text-second mx-auto hover:border-second font_primary'>Sign In</button>
                        </form>
                        <p><Link to='/' className='text-third underline mx-auto block text-center font_primary'>Earnestroi Home</Link></p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Loginpage;