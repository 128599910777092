import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LayoutThreeDash = () => {
    const [layout, setLayout] = useState('layout_three');
    const [header, setHeader] = useState("");
    const [forURL, setforURL] = useState("");
    const [name, setName] = useState('Earnestroi');
    const [email, setEmail] = useState('info@earnestroi.com');
    const [subheader, setSubheader] = useState('');
    const [banner, setBanner] = useState(null);
    const [apisubmit, setApisubmit] = useState({ description: '',  });
    const [tags, setTags] = useState('');

    const submitfunctuin = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('layout', layout);
        formData.append('header', header);
        formData.append('name', name);
        formData.append("email", email);
        formData.append("forURL", forURL);
        formData.append('subheader', subheader);
        formData.append('banner_img', banner);
        formData.append('description', apisubmit.description);
        formData.append('tags', tags);
        axios.post('https://api.earnestroi.com/api/blog',formData)
        .then( res => {
            if( res.data.message === 'Blog Created successfully' ) {
                toast.success('Blog Created successfully');
            }
        } )
        .catch( err => {
            if( err.message !== '' ) {
                toast.error('Something Went Wrong');
            }
        } )
    }
  return (
    <section className="py-8 w-full">
      <h2 className="text-third text-center text-3xl font_primary">Layout 3</h2>
      <div className="container mx-auto py-8">
        <form className="px-2" onSubmit={submitfunctuin}>
          <div className="justify-center items-center flex-col sm:flex-row hidden">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Layout: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="layout"
                value={layout}
                onChange={(e) => setLayout(e.target.value)}
                readOnly
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="layout_three"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                name="header"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
                type="text"
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Header"
              />
            </div>
          </div>
          <div className="hidden justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Name: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="name"
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Name"
              />
            </div>
          </div>
          <div className="hidden justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Email: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="email"
                disabled
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Email"
              />
            </div>
          </div>

          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Sub-Header: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="subheader"
                value={subheader}
                onChange={(e) => setSubheader(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">forURL: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="forURL"
                value={forURL}
                onChange={(e) => setforURL(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="forURL"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Banner: </h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="file"
                name="banner_img"
                onChange={(e) => setBanner(e.target.files[0])}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Sub-Header"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Description: </h2>
            </div>
            <div className="w-full sm:w-3/4 py-2">
              <CKEditor
                name="description"
                editor={ClassicEditor}
                value={apisubmit.description || ""}
                data="<p>Welcome to <b>Earnestroi</b> !!!<br />
                      <br /></p>"
                onReady={(editor) => {
                 
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setApisubmit({ ...apisubmit, description: data });
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col sm:flex-row">
            <div className="w-full  sm:w-1/4">
              <h2 className="font_primary text-second">Tags:</h2>
            </div>
            <div className="w-full sm:w-3/4">
              <input
                type="text"
                name="tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                placeholder="Tags"
              />
            </div>
          </div>
          <input
            type="submit"
            className="py-2 px-4 mt-4 rounded-lg font_secondary my-1 w-1/4 bg-second ml-auto hover:cursor-pointer block font_primary"
          />
        </form>
      </div>
    </section>
  );
}

export default LayoutThreeDash;