import React from "react";

const Pagination = ({ postperpage, totalposts, currentPage, paginate }) => {
  const pagenumbers = [];

  for (let i = 1; i <= Math.ceil(totalposts / postperpage); i++) {
    pagenumbers.push(i);
  }

  return (
    <div className="container mx-auto block">
      <nav className="w-full">
        <ul className="flex ml-auto justify-end">
          {pagenumbers.map((num) => (
            <li key={num}>
              <p
                className={`py-2 hover:cursor-pointer px-4 ${
                  num === currentPage
                    ? "bg-[#000] text-[#fff]"
                    : "bg-[#000] text-second"
                } rounded-lg mx-1 hover:text-[#fff]`}
                onClick={() => paginate(num)}
              >
                {num}
              </p>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    
  );
};

export default Pagination;
