import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footern from './Footern';
// import ModalBlog from './ModalBlog';
import WhatsappChat from './WhatsappChat';
import { toast } from 'react-toastify';

const BlogLayoutOne = () => {

  // const [show, setShow] = useState(false);
  const [response, setResponse] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");

  const { blogid } = useParams();

  
    useEffect(()=>{
      setTimeout(()=>{
        // setShow(true)
        }, 3000);
        
      }, []);
      
      const fetchfunction = async () => {
        try {
          const res = await axios.get(`https://api.earnestroi.com/api/getblog/${blogid}`);
          setResponse(res.data.data);
        } catch (err) {
          console.log('Error:', err);
        }
      };
      useEffect(() => {
        fetchfunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const timezone = new Date(response.updated_at).toLocaleString();

   

    const handleSubmit = async (e) => {
      e.preventDefault();

      const formData = {
        name: name,
        email: email,
        phone: mobile,
        purpose: position,
      };
      // formData.append("resume_url", resume_url);
      console.log(formData, "hjghg");
      try {
        const response = await axios.post(
          "https://dapib.stashack.cloud:2500/public/callback",
          formData
        );

        var res = response?.data;
        console.log(response, "hfdghdg");
        if (res?.Success) {
          toast.success(res?.Success);
          console.log(res?.Success, "hfdghdg");
        } else {
          console.log(res?.Error);
          toast.error(res?.Error);
        }
      } catch (error) {
        console.error("Error:", error);
        console.log("Response Data:", error.response.data.Error);
        toast.error(error.response.data.Error);
      }
    };

 
  
  return (
    <>
      {/* <ModalBlog show={ show } onClose={ () => { setShow( false ) } } /> */}
      <section className="">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-[70%] px-2 border-r-[1px] border-second">
              <div className="px-4 py-8">
                <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                  {response.header}
                </h2>
                <p>
                  by <b className="text-third">{response.name}</b> | {timezone}
                </p>
                <p className="font_secondary py-2">{response.subheader}</p>
                <img
                  src={`https://api.earnestroi.com/banner/${response.banner_img}`}
                  alt="blogimgearnestroi"
                  className="mx-auto block"
                />
              </div>
              <div
                className="p-4 font_secondary"
                dangerouslySetInnerHTML={{ __html: response.description }}
              ></div>
              <div className="py-6 px-4">
                <p className="font_secondary">
                  <b className="font_primary text-lg text-third">Comments: </b>{" "}
                  {response.comments}
                </p>
                <p className="font_secondary">
                  <b className="font_primary text-lg">Keywords: </b>{" "}
                  {response.tags}
                </p>
                <p className="font_primary text-xl text-second">
                  {response.name}
                </p>
                <p className="font_secondary text-lg">{response.email}</p>
              </div>
              <div className="px-4 py-4">
                <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                  OH HEY READER!
                </h2>
                <p className="text-base sm:text-lg font_secondary py-2">
                  We write about digital marketing, advertising, marketing
                  psychology & more. Sign up to get our exclusive in-depth
                  articles based on our learnings right in your inbox!
                </p>
                <form className="w-full lg:w-3/4">
                  <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p>
                  <input
                    type="email"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                  />
                  <input
                    type="submit"
                    className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                  />
                </form>
              </div>
              <div className="px-4 py-4">
                <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                  Submit a Comment
                </h2>
                <p className="text-base sm:text-lg font_secondary py-2">
                  Your email address will not be published. Required fields are
                  marked *
                </p>
                <form className="w-full">
                  <textarea
                    placeholder="Comment"
                    className="w-full border-2 border-third py-1 px-2 rounded-lg font_secondary"
                  />
                  <div className="flex my-1">
                    <input
                      type="text"
                      placeholder="Name*"
                      className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary"
                    />
                    <input
                      type="email"
                      placeholder="Email*"
                      className="border-2 border-third py-1 px-2 rounded-lg w-full ml-2 font_secondary"
                    />
                  </div>
                  <button
                    type="submit"
                    className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                  >
                    Submit Comment
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full lg:w-[30%] px-2">
              <div className="px-4 py-8">
                <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                  Schedule A Callback
                </h2>
                <form onSubmit={handleSubmit}>
                  <select
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    className="border-2 border-third py-1 px-2 rounded-lg font_secondary w-full"
                  >
                    <option value="purpose">Purpose</option>
                    <option value="marketingsolutions">
                      Marketing Solutions
                    </option>
                    <option value="technologysolutions">
                      Technology Solutions
                    </option>
                    <option value="partnerships">Partnerships/tieups</option>
                  </select>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  />
                  <input
                    type="number"
                    placeholder="Phone"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border-2 border-third py-1 px-2 rounded-lg w-full mr-2 font_secondary my-1"
                  />
                  <input
                    type="submit"
                    className="text-third border-2 border-third rounded-lg px-8 py-1 hover:cursor-pointer font_primary my-1"
                  />
                </form>
              </div>
              <div className="px-4 py-8">
                <h2 className="font_primary capitalize text-third text-xl lg:text-3xl py-4">
                  Recent Posts
                </h2>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Best Digital Marketing Strategies For Social Welfare
                    Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Digital Marketing Case Studies For Social Welfare Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Best Digital Marketing Strategies For Shipping & Logistics
                    Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Digital Marketing Case Studies For Shipping & Logistics
                    Industry
                  </a>
                </div>
                <div className="py-1">
                  <a
                    href="/"
                    className="hover:text-second hover:cursor-pointer text-base sm:text-lg font_secondary py-2 "
                  >
                    Best Digital Marketing Strategies For Health & Fitness
                    Industry
                  </a>
                </div>
              </div>
              <div className="px-4 py-4">
                <h2 className="font_primary capitalize text-second text-xl lg:text-3xl py-1">
                  OH HEY READER!
                </h2>
                <p className="text-base sm:text-lg font_secondary py-2 text-justify">
                  We write about digital marketing, advertising, marketing
                  psychology & more. Sign up to get our exclusive in-depth
                  articles based on our learnings right in your inbox!
                </p>
                <form className="w-full lg:w-3/4">
                  <p className="text-base sm:text-lg font_secondary py-1">
                    Email
                  </p>
                  <input
                    type="email"
                    className="border-2 border-third py-1 px-2 rounded-lg w-full font_secondary"
                  />
                  <input
                    type="submit"
                    className="text-third border-2 border-third rounded-lg my-2 px-8 py-1 hover:cursor-pointer font_primary"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footern />
      {/* <WhatsappChat /> */}
    </>
  );
}

export default BlogLayoutOne;