import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faLinkedin, faFacebook, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons';
import clutch from '../img/earnestroi-footer-clutch.png';
import designrush from '../img/earnestroi-footer-designrush.png';
import LiveTraffic from './LiveTraffic';
import TrafficCounter from './TrafficCounter';
import { Link } from 'react-router-dom';
const Footern = () => {
  return (
    <>
        <div className='pt-12'>
            <div className='container mx-auto pb-4'>
                <div className='flex flex-col md:grid grid-cols-2 justify-center gap-2'>
                    <div className='px-4'>
                        <h2 className='text-2xl text-second font_primary mb-4'>Services:</h2>
                        <div className='grid grid-cols-2 gap-1'>
                            <div className='pr-4'>
                                <h3 className='font_secondary text-second'><b>Marketing</b></h3>
                                <Link to='/smm'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; SMM</p></Link>
                                <Link to='/seo'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; SEO</p></Link>
                                <Link to='/graphic-design'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Graphic Design</p></Link>
                                <Link to='/content-marketing'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Content Marketing</p></Link>
                                <Link to='/web-development'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Web Development</p></Link>
                                <Link to='/email-marketing'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Email Marketing</p></Link>
                                <Link to='/ui-ux-development'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Ui/Ux Development</p></Link>
                                <Link to='/influencer-marketing'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Influencer Marketing</p></Link>
                            </div>
                            <div className='pl-4'>
                                <h3 className='font_secondary text-second'><b>Development</b></h3>
                                <Link to='/blockchain'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Blockchain</p></Link>
                                <Link to='/machine-learning'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Machine Learning</p></Link>
                                <Link to='/artificial-intelligence'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Artificial Intelligence</p></Link>
                                <Link to='/nft'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; NFT</p></Link>
                                <Link to='/token-development'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Token Development</p></Link>
                                <Link to='/smart-contract-development'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Smart Contract</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className='px-4'>
                        <div className='grid grid-cols-2 gap-1'>
                            <div className='pr-4'>
                                <h2 className='text-2xl text-second font_primary mb-4 invisible '>Services:</h2>
                                <h3 className='font_secondary text-second'><b>Development</b></h3>
                                <Link to='/web-three'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Web 3.0 Development</p></Link>
                                <Link to='/meta-verse-development'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Metaverse Development</p></Link>
                                <Link to='/semi-fungible-token-development'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Semi-Fungible Token</p></Link>
                                <Link to='/nft-market-place'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; NFT Marketplace</p></Link>
                                <Link to='/ico-development'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; ICO Development</p></Link>
                                <Link to='/crypto-wallet'><p className='mb-0 font_secondary text-[14px] sm:text-base'> &gt; Crypto Wallet</p></Link>
                            </div>
                            <div>
                                <div className=' flex justify-center lg:justify-start items-center'>
                                    <FontAwesomeIcon icon={ faFaceSmile } className='text-3xl xs:2x1 text-second mx-1 mb-4' />
                                    <h2 className='text-lg sm:text-2x2 xs:text-[12px] text-second font_primary mb-4 uppercase'> Earnestroi</h2>
                                </div>
                                <div className=''>
                                    <div className='mb-2'>
                                        <h3 className='font_secondary text-[14px] sm:text-base'><b className='text-second'>India Office</b></h3>
                                        <p className='font_secondary text-[14px] sm:text-base'>JMS Business Center, <br />Jogeshwari West, Mumbai.</p>
                                    </div>
                                    <div className='mb-2'>
                                        <h3 className='font_secondary text-[14px] sm:text-base'><b className='text-second'>UAE Office</b></h3>
                                        <p className='font_secondary text-[14px] sm:text-base'>Media City, Sharjah, UAE.</p>
                                    </div>
                                    <div className='mb-2'>
                                        <h3 className='font_secondary text-[14px] sm:text-base'><b className='text-second'>Contact Us</b></h3>
                                        <p className='font_secondary text-[14px] sm:text-base'><b className='font-medium'>Tel:</b> <a href="tel:+919222228848">+91 9222228848</a></p>
                                        <p className='font_secondary text-[14px] sm:text-base'><b className='font-medium'>Email:</b> <a href='mailto:info@earnestroi.com'>info@earnestroi.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sm:grid grid-cols-3 gap-2 py-4 flex flex-col px-2 justify-center items-center'>
                    <div className='flex items-center flex-col'>
                        <p className='font_secondary text-[14px] sm:text-base'><b className='text-second'>Listed on:</b></p>
                        <div className='flex items-center'>
                            <a rel="noreferrer noopener" target="_blank" href='https://www.designrush.com/agency/profile/earnestroi-technology'>
                                <img src={ designrush } className='mx-1 border-2 border-second p-1 rounded-lg' alt='footer listed on' />
                            </a>
                            <a rel="noreferrer noopener" target="_blank" href='https://clutch.co/profile/earnestroi-llp#summary'>
                                <img src={ clutch } className='mx-1 border-2 border-second p-1 rounded-lg' alt='footer listed on' />
                            </a>
                        </div> 
                    </div>
                    <div id='traffic' className='flex justify-center items-center'>
                        <LiveTraffic />               
                    </div>
                    <div id='trafficCounter' className='flex justify-center items-center flex-col'>
                        <TrafficCounter />
                        <h2 className='text-lg sm:text-2xl text-second font_primary uppercase'>Users Visited</h2>
                    </div>
                </div>
            </div>
            <div className='container mx-auto'>
                <div className='flex flex-col sm:flex-row items-center w-full justify-around py-2 border-t-2 border-second'> 
                    <p className='text-second text-base text-center font_secondary text-[14px] sm:text-base'>All rights reserved © 2019 - 2022 <a href='https://earnestroi.com/'>Earnestroi™ Solutions</a>.</p>
                    <div className='flex justify-start my-2 '>
                        <a href='https://www.instagram.com/earnestroi_technology/'><FontAwesomeIcon className='mx-2 text-second text-2xl' icon={faInstagram} /></a>
                        <a href='https://twitter.com/EarnestroiS'><FontAwesomeIcon className='mx-2 text-second text-2xl' icon={faTwitter} /></a>
                        <a href='https://www.linkedin.com/company/earnestroi/'><FontAwesomeIcon className='mx-2 text-second text-2xl' icon={faLinkedin} /></a>
                        <a href='https://www.facebook.com/EarnestRoiTechnology'><FontAwesomeIcon className='mx-2 text-second text-2xl' icon={faFacebook} /></a>
                        <a href='https://medium.com/@earnestrois'><FontAwesomeIcon className='mx-2 text-second text-2xl' icon={faMedium} /></a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footern;