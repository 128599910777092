

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Footern from "./Footern";

function CareerForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [ctc, setctc] = useState("");
  const [ectc, setectc] = useState("");
  const [positionval, setpositionval] = useState("");
  const [linkedln, setLinkedln] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [resume_url, setResumeUrl] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      email: email,
      phone: mobile,
      current_CTC: ctc,
      expected_CTC: ectc,
      role: positionval,
      linkedin_url: linkedln,
      facebook_url: facebook,
      twitter_url: twitter,
      // resume_url: `www.earnestroi.com/resume/${encodeURIComponent(resume_url)}`,
      resume_url: resume_url,
    };
    // formData.append("resume_url", resume_url);
    console.log(formData,"hjghg")
     try {
       const response = await axios.post(
         "https://dapib.stashack.cloud:2500/public/career",
         formData
       );

       var res = response?.data;
       console.log(response, "hfdghdg");
       if (res?.Success === "Applied for this position successfully!") {
         toast.success(res.Success);
         console.log(res.Success, "hfdghdg");
        
       } else {
         console.log(res?.Error);
         toast.error(res?.Error);
       }
     } catch (error) {
       console.error("Error:", error);
       console.log("Response Data:", error.response.data.Error);
       toast.error( error.response.data.Error);
     }
  };

//  const openResumeInNewTab = () => {
//    if (resumeBlob) {
//      const blobUrl = URL.createObjectURL(
//        new Blob([resumeBlob], { type: "application/pdf" })
//      );
//      window.open(blobUrl);
//    }
//  };

//  const handleChangeImage = (e) => {
//    setResumeBlob(e.target.files[0]);
//  };

  const handleChangeImage = (e) => {
    setResumeUrl(e.target.files[0]);
  };

  return (
    <>
      {/* ... rest of your form JSX ... */}

      <div className="py-10">
        <h2 className="text-second text-center text-2xl md:text-3xl lg:text-5xl mb-5 lg:mt-0 font_primary">
          Together at Earnestroi
        </h2>
        <p className="text-base sm:text-xl font-serif py-2 text-main text-center mb-5 capitalize">
          Be a Part of Earnestroi Family By showing Your interest with submit.
        </p>
        <form
          className="w-full lg:w-3/4 mx-auto bg-white shadow-md shadow-second rounded px-8 pt-6 pb-8 mb-4 border-b-2 border-second"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row my-2 items-center">
            {/* Name Input */}
            <div className="w-full lg:w-2/2 px-2">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="name"
              >
                Name: *
              </label>
              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="name"
                type="text"
                placeholder="Enter your name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* Mobile Input */}
            <div className="w-full lg:w-2/2 px-2 my-2">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="mobile"
              >
                Mobile No: *
              </label>
              <div className="flex p-1 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0">
                <select
                  className="appearance-none py-2 leading-tight focus:outline-none focus:shadow-outline"
                  id="country-code"
                  name="country-code"
                >
                  <option value="+91">+91</option>
                </select>
                <div className="border-l"></div>
                <input
                  className="appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="mobile"
                  type="tel"
                  placeholder="Enter your mobile number"
                  required
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* ... other form input sections ... */}

          <div className="flex flex-col md:flex-row my-2 items-center">
            <div className="w-full lg:w-2/2 px-2">
              <label
                className="block text-gray-700 font-bold mb-2 "
                htmlFor="email"
              >
                Email: *
              </label>
              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="email"
                type="email"
                placeholder="Enter your email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-2/2 px-2 my-2">
              <label
                className="block text-gray-700 font-bold mb-2 "
                htmlFor="option"
              >
                Choose Role: *
              </label>
              <select
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="option"
                name="option"
                required
                value={positionval}
                onChange={(e) => setpositionval(e.target.value)}
              >
                <option value="">--Select Role--</option>
                <option value="python">DIGITAL MARKETER</option>
                <option value="java">ALGORITHMIC TRADER DEVELOPER</option>
                {/* <option value="javascript">JavaScript</option> */}
              </select>
            </div>
          </div>

          <div className="flex flex-col md:flex-row my-2 items-center">
            <div className="w-full lg:w-2/2 px-2">
              <label
                className="block text-gray-700 font-bold mb-2 "
                htmlFor="email"
              >
                Current CTC: *
              </label>
              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="ctc"
                type="ctc"
                placeholder="Enter your current CTC"
                required
                value={ctc}
                onChange={(e) => setctc(e.target.value)}
              />
            </div>

            <div className="w-full lg:w-2/2 px-2">
              <label
                className="block text-gray-700 font-bold mb-2 "
                htmlFor="email"
              >
                Expected CTC: *
              </label>
              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="ectc"
                type="ectc"
                placeholder="Enter your expected CTC"
                required
                value={ectc}
                onChange={(e) => setectc(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row my-2 items-center">
            <div className="w-full lg:w-2/2 px-2">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Linkedln:
              </label>
              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="email"
                type="text"
                placeholder="Enter your linkedln id"
                value={linkedln}
                onChange={(e) => setLinkedln(e.target.value)}
              />
            </div>

            <div className="w-full lg:w-2/2 px-2">
              <label
                className="block text-gray-700 font-bold mb-2 "
                htmlFor="email"
              >
                Facebook:
              </label>

              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="email"
                type="text"
                placeholder="Enter your facebook id"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row my-2 items-center">
            <div className="w-full lg:w-2/2 px-2">
              <label
                className="block text-gray-700 font-bold mb-2 "
                htmlFor="email"
              >
                Twitter:
              </label>
              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="email"
                type="text"
                placeholder="Enter your twitter id"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-2/2 px-2 my-2">
              <label
                className="block text-gray-700 font-bold mb-2 "
                htmlFor="resume_url"
              >
                Upload Resume: *
              </label>
              <input
                className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                id="resume_url"
                type="file"
                accept=".pdf"
                value={resume_url}
                required
                onChange={(e) => setResumeUrl(e.target.value)}
                // onChange={handleChangeImage}
              />
            </div>
          </div>

          <div className="w-full px-2 flex items-center justify-center">
            <input
              onClick={handleSubmit}
              type="submit"
              className="px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary hover:border-second font_secondary text-second hover:text-second bg-main hover:bg-[white] focus:outline-0"
            />
          </div>
        </form>
      </div>
      <Footern />
    </>
  );
}

export default CareerForm;

