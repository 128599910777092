export const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: "/about-us",
  },
  {
    title: "Services",
    url: "/services",
    // submenu: [
    //   {
    //     title: 'Careers',
    //     url: 'careers',
    //   },
    // ],
  },
  {
    title: "Development Services",
    url: "/blockchain",
    submenu: [
      {
        title: "Blockchain",
        url: "blockchain",
      },
      {
        title: "Machine Learning (ML)",
        url: "machine-learning",
      },
      {
        title: "Artificial Intelligence (AI)",
        url: "artificial-intelligence",
      },
      {
        title: "NFT",
        url: "nft",
      },
      {
        title: "Token Development",
        url: "token-development",
      },
      {
        title: "Smart Contract Development",
        url: "smart-contract-development",
      },
      {
        title: "Bot Development",
        url: "bot-development",
      },
    ],
  },
  {
    title: "Marketing Services",
    url: "/smm",
    submenu: [
      {
        title: "SMM",
        url: "smm",
      },
      {
        title: "SEO",
        url: "seo",
      },
      {
        title: "Graphic Design",
        url: "graphic-design",
      },
      {
        title: "Content Marketing",
        url: "content-marketing",
      },
      {
        title: "Web Development",
        url: "web-development",
      },
      {
        title: "Email Marketing",
        url: "email-marketing",
      },
      {
        title: "Ui/Ux Design",
        url: "ui-ux-development",
      },
      {
        title: "Influencer Marketing",
        url: "influencer-marketing",
      },
      // {
      //   title: 'Dashboard',
      //   url: 'admin/blogs',
      // },
    ],
  },
  // {
  //   title: "Blog",
  //   url: "/blog",
  // },
];
  