import './App.css';
import { Routes, Route} from 'react-router-dom';
import Layout from './Components/Layout';
import DMA from './Components/DMA';
import AboutUs from './Components/AboutUs';
import SMM from './Components/SMM';
import GraphicDesign from './Components/GraphicDesign';
import SEO from './Components/SEO';
import ContentM from './Components/ContentM';
import Careers from './Components/Careers';
import Blockchain from './Components/Blockchain';
import MachineLeaning from './Components/MachineLeaning';
import ArtificialIntelligence from './Components/ArtificialIntelligence';
import NftDevelopment from './Components/NftDevelopment';
import TokenDevelopment from './Components/TokenDevelopment';
import SmartContract from './Components/SmartContract';
import Webthree from './Components/Webthree';
import EmailMarketing from './Components/EmailMarketing';
import WebDevelopment from './Components/WebDevelopment';
import MetaverseDevelopment from './Components/MetaverseDevelopment';
import UiUxDevelopment from './Components/UiUxDevelopment';
import InfluencerMarketing from './Components/InfluencerMarketing';
import SemiNFT from './Components/SemiNFT';
import IcoDevelopment from './Components/IcoDevelopment';
import NftMarketplace from './Components/NftMarketplace';
import CryptoWallet from './Components/CryptoWallet';
import ContactsL from './Components/ContactsL';
import EgModalShow from './Components/EgModalShow';
import LandingOne from './Components/LandingOne';
import WeAreHiring from './Components/WeAreHiring';
import Panel from './Components/Panel';
import Loginpage from './Components/Loginpage';
import PageNotFound from './Components/PageNotFound';
import DisplayBlogs from './Components/DisplayBlogs';
import Admin from './Components/Admin';
import DashboardBlogs from './Components/DashboardBlogs';
import ContactDetailsDash from './Components/ContactDetailsDash';
import LayoutOneDash from './Components/LayoutOneDash';
import LayoutTwoDash from './Components/LayoutTwoDash';
import LayoutThreeDash from './Components/LayoutThreeDash';
import BlogLayoutOne from './Components/BlogLayoutOne';
import BlogLayoutTwo from './Components/BlogLayoutTwo';
import BlogLayoutThree from './Components/BlogLayoutThree';
import ApplyForm from './Components/ApplyForm';
import ResumeDash from './Components/ResumeDash';
import CryptoTrading from './Components/CryptoTrading';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Layout />}>
          <Route index element={<DMA />} />
          <Route path="services" element={<Careers />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="smm" element={<SMM />} />
          <Route path="graphic-design" element={<GraphicDesign />} />
          <Route path="seo" element={<SEO />} />
          <Route path="bot-development" element={<CryptoTrading />} />
          <Route path="content-marketing" element={<ContentM />} />
          <Route path="blockchain" element={<Blockchain />} />
          <Route path="machine-learning" element={<MachineLeaning />} />
          <Route
            path="artificial-intelligence"
            element={<ArtificialIntelligence />}
          />
          <Route path="nft" element={<NftDevelopment />} />
          <Route path="token-development" element={<TokenDevelopment />} />
          <Route
            path="smart-contract-development"
            element={<SmartContract />}
          />
          <Route path="web-three" element={<Webthree />} />
          <Route path="email-marketing" element={<EmailMarketing />} />
          <Route path="web-development" element={<WebDevelopment />} />
          <Route
            path="meta-verse-development"
            element={<MetaverseDevelopment />}
          />
          <Route path="ui-ux-development" element={<UiUxDevelopment />} />
          <Route
            path="influencer-marketing"
            element={<InfluencerMarketing />}
          />
          <Route path="semi-fungible-token-development" element={<SemiNFT />} />
          <Route path="ico-development" element={<IcoDevelopment />} />
          <Route path="nft-market-place" element={<NftMarketplace />} />
          <Route path="crypto-wallet" element={<CryptoWallet />} />
          <Route path="contacts-1" element={<ContactsL />} />
          <Route path="modal-compo" element={<EgModalShow />} />
          <Route path="blog/:blogid" element={<BlogLayoutOne />} />
          <Route path="blog/:blogid" element={<BlogLayoutTwo />} />
          <Route path="blog/:blogid" element={<BlogLayoutThree />} />
          <Route path="landing-one" element={<LandingOne />} />
          <Route path="we-are-hiring" element={<WeAreHiring />} />
          <Route path="panel" element={<Panel />} />
          <Route path="sign-In" element={<Loginpage />} />
          <Route path="blog" element={<DisplayBlogs />} />
          <Route path="apply" element={<ApplyForm />} />
          <Route path="admin" element={<Admin />}>
            <Route path="blogs" element={<DashboardBlogs />} />
            <Route path="contact-details" element={<ContactDetailsDash />} />
            <Route path="resumes" element={<ResumeDash />} />
            <Route path="layoutone" element={<LayoutOneDash />} />
            <Route path="layouttwo" element={<LayoutTwoDash />} />
            <Route path="layoutthree" element={<LayoutThreeDash />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
