import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

const Header = () => {

  
  const userMessage = "Hi Team, I would like to discuss..."; // Custom message
  const emailHref = `mailto:info@earnestroi.com?subject=Let's Talk&body=${encodeURIComponent(userMessage)}`;


  useEffect(() => {
    const header = document.getElementById("main-header");
    const handleScroll = () => {
      if (window.scrollY > header.offsetHeight) {
        header.classList.add("fixed");
      } else {
        header.classList.remove("fixed");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [headerVisible, setHeaderVisible] = useState(true);

  const handleCancelClick = () => {
    setHeaderVisible(false);
  };

  const [words, setWords] = useState([
    " Get your 1st consultation for FREE",
    "Are You Ready to 3X your sales?",

  ]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change this value to adjust the interval (in milliseconds)

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <>
      <header id="main-header" className="w-full z-50">
        <div className={`w-full z-50 ${headerVisible ? "" : "hidden"}`}>
          {headerVisible && (
            <div className="relative">
              <button
                onClick={handleCancelClick}
                className="absolute top-2 right-2 text-gray-500 hover:text-red-500 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="w-full py-2 hidden md:block bg-second">
                <div className="container mx-auto block">
                  <div className="flex justify-center items-center px-2 ">
                    {/* <h2 className="text-center textgreenn domine text-sm sm:text-base md:text-lg lg:text-xl capitalize">
                      Get your 1st consultation for FREE
                    </h2> */}
                    <div className="scroller text-center font-extrabold text-2xl">
                      {words.map((word, index) => (
                        <h2
                          key={index}
                          className={`${index === currentWordIndex
                              ? "animate-slide-up"
                              : "hidden"
                            }`}
                        >
                          {word}
                          {index !== words.length - 1 && <br />}
                        </h2>
                      ))}
                    </div>

                    {/* <h1>Get your 1st consultation for FREE</h1>
                    <h1> Are You Ready to 3X your sales?</h1> */}
                    {/* <TypeAnimation
                      sequence={[
                        " Get your 1st consultation for FREE",
                        1000,
                        " Are You Ready to 3X your sales?",
                        2000,
                      ]}
                      wrapper="span"
                      cursor={true}
                      repeat={Infinity}
                      style={{
                        color: "#000",
                        fontWeight: "900",
                        fontSize: "1.5rem",
                      }}
                    /> */}
                    <a
                      href={emailHref}
                      className="animate-topbtn text-center ml-2 sm:ml-6 text-[white] bg-main focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] sm:text-sm md:text-base px-2 py-1 sm:px-4 sm:py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 focus:outline-none dark:focus:ring-blue-800"
                      aria-label="Contact us via email"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click Here
                    </a>
                    {/* <Link
                      to="/contacts-1"
                      className="animate-topbtn text-center ml-2 sm:ml-6 text-[white] bg-main focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] sm:text-sm md:text-base px-2 py-1 sm:px-4 sm:py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Click Here
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className=" ">
          {/* <div className="w-full py-2 hidden md:block  bg-second ">
            <div className="container mx-auto block">
              <div className="flex justify-center items-center px-2">
                <h2 className="text-center textgreenn domine text-sm sm:text-base md:text-lg lg:text-xl capitalize">
                  Get your 1st consultation for FREE
                </h2>

                <button
                  onClick={handleCancelClick}
                  className="text-center ml-2 sm:ml-6 text-[white] bg-main focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[12px] sm:text-sm md:text-base px-2 py-1 sm:px-4 sm:py-2 md:px-5 md:py-2.5 mr-1 md:mr-2 focus:outline-none dark:focus:ring-blue-800"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div> */}
          <div className="  nav-area w-full p-0 ">
            <Navbar />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;