import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footern from './Footern';
import axios from 'axios';

const DisplayBlogs = () => {

    const [response, setResponse] = useState([]);

    const fetchfunction = async () => {

        const res = await axios
            .get('https://api.earnestroi.com/api/blog')
            .catch( err => console.log('Error: ', err) )
        const sortedBlogs = res.data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setResponse(sortedBlogs);

    }

    useEffect( () => {

        fetchfunction();

    }, [] )

    
    const abc = 'https://api.earnestroi.com/banner/';

  return (
    <>
      <section className="">
        <div className="container mx-auto ">
          <h2 className="text-second font_primary text-center text-5xl "    >
            Earnestroi Blogs
          </h2>
        </div>
        <div className="my-10 container mx-auto px-2">
          <div className="grid grid-cols1 sm:grid-cols-2 sm:gap-1 md:grid-cols-3 md:gap-4 xl:grid-cols-4 xl:gap-6">
            {response &&
              response.map((resp) => {
                var { id, header, forURL, banner_img, subheader, layout } =
                  resp;

                let path = "";
                if (layout === "layout_one") {
                  path = "blog";
                }
                if (layout === "layout_two") {
                  path = "blog";
                }
                if (layout === "layout_three") {
                  path = "blog";
                }

                return (
                  <Link to={`/${path}/${forURL}`} key={forURL}>
                    <div className="px-1">
                      <img
                        src={abc + banner_img}
                        alt="blog imgs"
                        className="mx-auto block rounded-xl my-1 h-36"
                      />
                      <h2 className="text-third font_primary text-xl my-1 text-justify line-clamp-3">
                        {header}
                      </h2>
                      <p className="font_secondary text-base text-justify line-clamp-3">
                        {subheader}
                      </p>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </section>
      <Footern />
    </>
  );
}

export default DisplayBlogs;