import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaginationContact from './PaginationContact';

const ContactDetailsDash = () => {
  const [response, setResponse] = useState([]);

  const [currentpage, setcurrentpage] = useState(1);
  const [postperpage] = useState(10);

  // const fetchfunction = async () => {
  //   const JWT = localStorage.getItem("JWT");
  //     const formData = {
  //       JWT: JWT,
  //     };
  //   const response = await axios
  //     .get("https://dapib.stashack.cloud:2500/private/view_contact", formData)
  //     .catch((err) => console.log(err));
  //   setResponse(response?.data?.Success);
  //   console.log(response?.data?.Success, "jhjg");
  // }

  // const fetchfunction = () => {
  //   const JWT = localStorage.getItem("JWT");
  //   const formData = {
  //     JWT: JWT,
  //   };
  //   var response = axios({
  //     method: "POST",
  //     // headers: { "Content-Type": "multipart/form-data" },
  //     url: "https://dapib.stashack.cloud:2500/private/view_contact",
  //     data: formData,
  //   })
  //     .then(async function (response) {
  //       console.log("response", response);
  //       //  console.log("Response data:", response.data);
  //       //  const res = await response?.data?.Success;
  //       //   const sortedData = res.sort(
  //       //     (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
  //       //   );
  //       //   console.log(response,"hfhdgf")
  //       //   setResponse(sortedData.reverse());
  //       //   toast(response.data.Success);
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });
  // };


 const fetchfunction = async () => {
   try {
     const JWT = localStorage.getItem("JWT");

     const formData = {
       jwt: JWT, // Change "JWT" to "jwt"
     };

     const response = await axios.post(
       "https://dapib.stashack.cloud:2500/private/view_contact",
       formData
     );

     const responseData = response.data.Success;
     console.log("Response:", responseData);
     setResponse(response?.data?.Success);
    console.log(response,"dfhdgf")
     // Handle the response data as needed
     // For example, update state with the data
     // setState(responseData);
   } catch (error) {
     console.error("Error:", error);

     if (error.response) {
       console.log("Response Data:", error.response.data);

       // Handle specific error cases based on the response data
       // For example, show an error message to the user
       // toast.error(error.response.data.Error);
     }
   }
 };

  const sorted = response.sort((a, b) => {
    const dateA = new Date(a?.Datetime);
    const dateB = new Date(b?.Datetime);
    return dateB - dateA;
  });

  //get current response

  const indexoflastpost = currentpage * postperpage;
  const indexoffirstpost = indexoflastpost - postperpage;
  const datas = sorted.slice(indexoffirstpost, indexoflastpost);
  console.log("datas", response);

  const paginate = (num) => {
    setcurrentpage(num);
  };

   const deletefunction = async (id) => {
     try {
       const JWT = localStorage.getItem("JWT");

       const formData = {
         jwt: JWT, // Change "JWT" to "jwt"
         id:id,
       };

       const response = await axios.post(
         "https://dapib.stashack.cloud:2500/private/delete_contact",
         formData
       );

       const responseData = response?.data?.Success;
       toast.success(responseData);
       fetchfunction();
      //  setResponse(response?.data?.Success);
       console.log(response, "dfhdgf");
       // Handle the response data as needed
       // For example, update state with the data
       // setState(responseData);
     } catch (error) {
       console.error("Error:", error);

       if (error.response) {
         toast.error("Response Data:", error?.response?.Error);

         // Handle specific error cases based on the response data
         // For example, show an error message to the user
         // toast.error(error.response.data.Error);
       }
     }
   };


  // const deletefunction = (pars) => {
  //   axios
  //     .delete(`https://api.earnestroi.com/api/contact/${pars}`)
  //     .catch((err) => console.log("Error: ", err))
  //     .then((response) => {
  //       if (response.data.message === "Contact delete successfully") {
  //         toast.success("Contact Deleted Successfully");
  //       } else {
  //         toast.error("Something Went Wrong");
  //       }
  //     });
  // };

  useEffect(() => {
    fetchfunction();
  }, []);

  return (
    <>
      <section className="w-full mb-8">
        <div className="container mx-auto mt-12 px-2 sm:px-4">
          <div className="container mx-auto">
            <h2 className="text-second font_primary text-center text-6xl">
              Contact Details
            </h2>
          </div>
          <Table className="mt-10">
            <Thead className="text-third font_primary">
              <Tr>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal rounded-tl-xl">
                  Name
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Email
                </Th>
                {/* <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal'>Phone</Th> */}
                {/* <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal'>URL</Th> */}
                {/* <Th className='text-start p-2 border-[1px] bg-main border-[white] font-normal'>Position </Th> */}
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal">
                  Comments
                </Th>
                <Th className="text-start p-2 border-[1px] bg-main border-[white] font-normal rounded-tr-xl">
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody className="border-[1px] border-second">
              {datas && datas?.map((resp) => {
                const { FormID, Name, Email, Message } = resp;

                return (
                  <Tr key={FormID}>
                    <Td className="border-[1px] p-1 border-second">{Name}</Td>
                    <Td className="border-[1px] p-1 border-second">{Email}</Td>
                    {/* <Td className='border-[1px] p-1 border-second'>{ phone }</Td>                 */}
                    {/* <Td className='border-[1px] p-1 border-second'>{ <a href={website_url} target="_blank" rel="noreferrer">{website_url}</a> }</Td>                 */}
                    {/* <Td className='border-[1px] p-1 border-second'>{ position }</Td>                 */}
                    <Td className="border-[1px] p-1 border-second">
                      {Message}
                    </Td>
                    <Td className="border-[1px] p-1 border-second text-center">
                      <span
                        className="hover:cursor-pointer text-[red]"
                        onClick={() => deletefunction(FormID)}
                      >
                        Delete
                      </span>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
        <PaginationContact
          paginate={paginate}
          totalposts={response?.length}
          postperpage={postperpage}
        />
      </section>
    </>
  );
}

export default ContactDetailsDash;