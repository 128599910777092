import React, { useEffect } from 'react';
import img_2_1 from '../img/earnestroi-careers.webp';
import img_2_2 from '../img/earnestroi-careers-aboutus.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footern from './Footern';
// import WhatsappChat from './WhatsappChat';
// import { Helmet } from 'react-helmet';

const WeAreHiring = () => {

  useEffect(() => {

    Aos.init({

      disable: function () {
        var maxWidth = 1023;
        return window.innerWidth < maxWidth;
      }

    });


    $(document).ready(function () {
      $(this).scrollTop(0);
    });

  });

  useEffect(() => {

    Aos.init({ duration: 3000 });

  })

  const userMessage = "Hi Team, I would like to discuss..."; // Custom message
  const emailHref = `mailto:career@earnestroi.com?subject=Let's Talk&body=${encodeURIComponent(userMessage)}`;


  return (
    <>
      {/* <Helmet>
          <title>Earnestroi Technology | Careers | Online Marketing Agency</title>
          <meta name="title" content="Earnestroi Technology | Careers | Online Marketing Agency," />
          <meta name="description" content='Earnestroi have plans to make sure you are on the right track with your business for marketing and branding, that you have time to grow professionally in the Digital World.' />
          <meta name="keywords" content="digital marketing, digital marketing company, digital marketing agency, online marketing, project management,blockchain developers" />
          <meta name="robots" content="index,follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
        </Helmet> */}
      {/* <section className="mt-[3.5rem] lg:mt-[4rem] py-10">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row justify-center items-center">
            <img src={img_2_1} className="" alt="img_2_1" />
            <h1
              className="text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary"
              data-aos="fade-right"
            >
              Careers
            </h1>
          </div>
        </div>
        <div className="container mx-auto">
          <div className="flex justify-center py-4 lg:py-10">
            <Link to="/">
              <h2 className="mx-2 text-second font_primary">
                <FontAwesomeIcon
                  icon={faHome}
                  className="mx-2 text-second font-primary"
                />
                Digital Marketing Agency
              </h2>
            </Link>
            <h2 className="mx-2">/</h2>
            <Link to="/aboutus">
              <h2 className="mx-2 text-second font_primary">About Us</h2>
            </Link>
            <h2 className="mx-2">/</h2>
            <h2 className="mx-2 text-second font_primary">Careers</h2>
          </div>
        </div>
        <div className="container mx-auto py-10">
          <div className="grid-cols-2 flex flex-col lg:flex-row justify-center items-center">
            <div className="px-2 w-full lg:w-1/2">
              <div className="border-b-2 border-second w-full mb-4 pb-[2px]">
                <span className="border-b-2 border-second text-3xl text-second font_primary">
                  Are You ...
                </span>
              </div>
              <p
                className="text-base sm:text-lg mb-4 font_secondary"
                data-aos="fade-left"
              >
                An aspiring creative, looking for an unorthodox digital
                marketing agency? An agency where working remotely is the norm
                and grueling office small talk is the exception.
              </p>
              <p
                className="text-base sm:text-lg mb-4 font_secondary"
                data-aos="fade-left"
              >
                Our team at Earnestroi is ever-expanding and we are constantly
                on the lookout for talented and driven individuals, with a
                wicked sense of humor, seeking to expand their skill set.
              </p>
              <p
                className="text-base sm:text-lg mb-4 font_secondary"
                data-aos="fade-left"
              >
                Whether you are a marketer, graphic designer, content creator,
                copywriter or overall creative genius, we at Earnestroi will
                find an adaptive role that will be a perfect fit for you.{" "}
              </p>
            </div>
            <div className="px-2 w-full lg:w-1/2">
              <img src={img_2_2} alt="img_2_2" className="mx-auto block" />
            </div>
          </div>
        </div>
      </section> */}
      <section className="bg-main py-0 lg:pb-16">
        <div className="container mx-auto py-10">
          <div className="text-center mb-6 pb-[2px]">
            <span className="border-b-4 border-second text-3xl text-[white] font_primary">
              Positions Available
            </span>
          </div>
          <div className="grid-cols-2 flex flex-col lg:flex-row justify-around">
            <div
              className="py-10 lg:py-0 px-2 w-full lg:w-[40%]"
              data-aos="fade-up"
            >
              <p className="font_primary text-base sm:text-lg mb-4 uppercase text-[white] font_secondary">
                {" "}
                1) Digital Marketer
              </p>
              <p className="text-base sm:text-lg mb-4 text-[white] font_secondary">
                Job Duties
              </p>
              <ul className="mb-4 list-disc pl-6">
                <li className="text-[white] font_secondary">
                  We are seeking a highly creative and analytical Digital
                  Marketer to join our team. The ideal candidate should have a
                  strong understanding of digital marketing concepts and
                  techniques, including SEO, SEM, PPC, social media, email
                  marketing, and analytics.
                </li>
              </ul>

              <p className="text-base sm:text-lg mb-4 text-[white] font_secondary">
                Responsibilities
              </p>

              <ul className="mb-4 list-disc pl-6">
                <li className="text-[white] font_secondary">
                  Develop and execute digital marketing campaigns across
                  multiple channels, including social media, email, SEO, SEM,
                  PPC, and display advertising.
                </li>

                <li className="text-[white] font_secondary">
                  Monitor and analyze campaign performance using web analytics
                  tools and recommend improvements to achieve desired results.
                </li>
                <li className="text-[white] font_secondary">
                  Collaborate with internal teams to develop creative assets,
                  landing pages, and other marketing collateral.
                </li>
                <li className="text-[white] font_secondary">
                  Manage and maintain the company's social media presence,
                  including content creation, community engagement, and social
                  advertising.
                </li>
                <li className="text-[white] font_secondary">
                  Stay up-to-date with industry trends and best practices, and
                  implement new strategies to improve digital marketing
                  performance.
                </li>
              </ul>

              <p className="text-base sm:text-lg mt-2 mb-4 text-[white] font_secondary">
                Desired Candidate Profile
              </p>

              <ul className="mb-4 list-disc pl-6">
                <li className="text-[white] font_secondary">
                  Minimum 2 years of experience in digital marketing, with a
                  proven track record of developing and executing successful
                  campaigns.
                </li>
                <li className="text-[white] font_secondary">
                  Proficient in digital marketing tools and channels, including
                  social media, email marketing, SEO, SEM, PPC, and analytics.
                </li>
                <li className="text-[white] font_secondary">
                  Strong analytical and problem-solving skills, with the ability
                  to interpret data and make strategic recommendations.
                </li>
                <li className="text-[white] font_secondary">
                  Excellent communication and collaboration skills, with the
                  ability to work effectively with cross-functional teams
                </li>
              </ul>

              {/* <li className="text-[white] font_secondary">
                If you are a creative and results-driven Digital Marketer
                looking for an exciting opportunity to drive growth and
                engagement for a dynamic company, we encourage you to apply.
              </li> */}

              <p className="text-base sm:text-lg mt-2 mb-4 text-[white] font_secondary">
                Note:
              </p>

              <ul>
                <li className="text-[white] font_secondary">
                  - Only Mumbai based candidates should apply for this position.
                </li>
                {/* <li className="text-[white] font_secondary">
                  - Only Mumbai-based candidates should apply.
                </li> */}
              </ul>

              <p className="text-base sm:text-lg mt-2 mb-4 text-[white] font_secondary">
                Perks and Benefits:
              </p>

              <ul>
                <li className="text-[white] font_secondary">
                  - Compensation will be based on qualifications and experience
                </li>
              </ul>
            </div>
            <div
              className="py-10 lg:py-0 px-2 w-full lg:w-[40%]"
              data-aos="fade-up"
            >
              <p className="font_primary text-base sm:text-lg mb-4 uppercase text-[white] font_secondary">
                {" "}
                2) Algorithmic Trader / Quantitative Trader Developer
              </p>
              <p className="text-base sm:text-lg mb-4 text-[white] font_secondary">
                Job Duties
              </p>
              <ul className="mb-4 list-disc pl-6">
                <li className="text-[white] font_secondary">
                  We are looking for an experienced Algorithmic Trader to join
                  our team. The ideal candidate will have a strong understanding
                  of Python and quantitative finance. They will be responsible
                  for developing and implementing algorithmic trading
                  strategies, as well as monitoring and managing live trading
                  systems. Minimum 6 months of experience in algorithmic trading
                  is mandatory.
                </li>
              </ul>

              <p className="text-base sm:text-lg mb-4 text-[white] font_secondary">
                Responsibilities
              </p>

              <ul className="mb-4 list-disc pl-6">
                <li className="text-[white] font_secondary">
                  Develop and implement algorithmic trading strategies using
                  Python
                </li>
                <li className="text-[white] font_secondary">
                  Backtest and optimize trading strategies
                </li>
                <li className="text-[white] font_secondary">
                  Monitor and manage live trading systems
                </li>
                <li className="text-[white] font_secondary">
                  Work with other quantitative analysts and traders to develop
                  and implement trading strategies
                </li>
                <li className="text-[white] font_secondary">
                  Stay up-to-date on the latest developments in algorithmic
                  trading
                </li>
              </ul>

              <p className="text-base sm:text-lg mt-2 mb-4 text-[white] font_secondary">
                Preferred Candidate Profile
              </p>

              <ul className="mb-4 list-disc pl-6">
                <li className="text-[white] font_secondary">
                  Bachelor's degree in math, engineering, or a related field
                </li>
                <li className="text-[white] font_secondary">
                  Minimum 6 months of experience in algorithmic trading
                </li>
                <li className="text-[white] font_secondary">
                  Strong understanding of Python
                </li>
                <li className="text-[white] font_secondary">
                  Experience with quantitative finance concepts
                </li>
                <li className="text-[white] font_secondary">
                  Excellent problem-solving and analytical skills
                </li>
                <li className="text-[white] font_secondary">
                  Ability to work independently and as part of a team
                </li>
              </ul>

              <p className="text-base sm:text-lg mt-2 mb-4 text-[white] font_secondary">
                Perks and benefits
              </p>

              <ul className="mb-4 list-disc pl-6">
                <li className="text-[white] font_secondary">
                  Competitive salary and benefits package
                </li>
                <li className="text-[white] font_secondary">
                  Opportunity to work in a fast-paced and dynamic environment
                </li>
                <li className="text-[white] font_secondary">
                  Chance to make a significant impact on the company's trading
                  performance
                </li>
                <li className="text-[white] font_secondary">
                  Experience with quantitative finance concepts
                </li>
              </ul>

              <p className="text-base sm:text-lg mt-2 mb-4 text-[white] font_secondary">
                Note:
              </p>

              <ul>
                <li className="text-[white] font_secondary">
                  - Only Mumbai based candidates should apply for this position
                </li>
                <li className="text-[white] font_secondary">
                  - Minimum 6 months of experience in algorithmic trading is
                  mandatory.
                </li>
              </ul>

              <ul>
                <li className="text-[white] font_secondary mb-4">
                  based on the candidate's knowledge and experience.
                </li>
              </ul>

              {/* <p className="text-base sm:text-lg mb-4 text-[white] font_secondary">
                Bonus skills: Video editing experience
              </p> */}

              <a
                href={emailHref}
                className="relative p-2  my-4 md:my-0 border-2 border-[#fff] text-[white] rounded-lg font_primary"
                aria-label="Contact us via email"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apply{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-second pl-4 animate-right_left"
                />
              </a>
              {/* <a
                href="/apply"
                className="relative p-2  my-4 md:my-0 border-2 border-[#fff] text-[white] rounded-lg font_primary"
              >
                Apply{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-second pl-4 animate-right_left"
                />
              </a> */}
            </div>
          </div>
        </div>
      </section>
      <section className="mt-[3.5rem] lg:mt-[4rem] py-10">
        {/* <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row justify-center items-center">
            <img src={img_2_1} className="" alt="img_2_1" />
            <h1
              className="text-second text-5xl lg:text-6xl mb-4 mt-4 lg:mt-0 capitalize font_primary"
              data-aos="fade-right"
            >
              Careers
            </h1>
          </div>
        </div> */}
        <div className="container mx-auto">
          {/* <div className="flex justify-center py-4 lg:py-10">
            <Link to="/">
              <h2 className="mx-2 text-second font_primary">
                <FontAwesomeIcon
                  icon={faHome}
                  className="mx-2 text-second font-primary"
                />
                Digital Marketing Agency
              </h2>
            </Link>
            <h2 className="mx-2">/</h2>
            <Link to="/aboutus">
              <h2 className="mx-2 text-second font_primary">About Us</h2>
            </Link>
            <h2 className="mx-2">/</h2>
            <h2 className="mx-2 text-second font_primary">Careers</h2>
          </div> */}
        </div>
        <div className="container mx-auto py-1">
          <div className="grid-cols-2 flex flex-col lg:flex-row justify-center items-center">
            <div className="px-2 w-full lg:w-1/2">
              <div className="border-b-2 border-second w-full mb-4 pb-[2px]">
                <span className="border-b-2 border-second text-3xl text-second font_primary">
                  Are You ...
                </span>
              </div>
              <p
                className="text-base sm:text-lg mb-4 font_secondary"
                data-aos="fade-left"
              >
                An aspiring creative, looking for an unorthodox digital
                marketing agency? An agency where working remotely is the norm
                and grueling office small talk is the exception.
              </p>
              <p
                className="text-base sm:text-lg mb-4 font_secondary"
                data-aos="fade-left"
              >
                Our team at Earnestroi is ever-expanding and we are constantly
                on the lookout for talented and driven individuals, with a
                wicked sense of humor, seeking to expand their skill set.
              </p>
              <p
                className="text-base sm:text-lg mb-4 font_secondary"
                data-aos="fade-left"
              >
                Whether you are a marketer, graphic designer, content creator,
                copywriter or overall creative genius, we at Earnestroi will
                find an adaptive role that will be a perfect fit for you.{" "}
              </p>
            </div>
            <div className="px-2 w-full lg:w-1/2">
              <img src={img_2_2} alt="img_2_2" className="mx-auto block" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="py-8 lg:py-10">
        <div className="container mx-auto w-full lg:w-1/2">
          <p className="text-3xl text-second lg:my-4 font_primary">
            Submit Your Application
          </p>
          <form>
            <div className="flex flex-col md:flex-row my-2">
              <div className="w-full lg:w-1/2 px-2">
                <p className="font_secondary">First Name *</p>
                <input
                  type="text"
                  required
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <p className="font_secondary">Last Name *</p>
                <input
                  type="text"
                  required
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row my-2">
              <div className="w-full lg:w-1/2 px-2">
                <p className="font_secondary">Email *</p>
                <input
                  type="text"
                  required
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <p className="font_secondary">Phone</p>
                <input
                  type="number"
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row my-2">
              <div className="w-full lg:w-1/2 px-2">
                <p>Position: </p>
                <select
                  name="positions"
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                  id="positions"
                >
                  <option value="">Select Position</option>
                  <option value="engineer_e">Electrical Engineer</option>
                  <option value="data_scientist">Data Scientist</option>
                  <option value="ai_researcher">
                    Artificial Intelligence Researcher
                  </option>
                  <option value="dl_engineer">Deep Learning Engineer</option>
                  <option value="other">Other: Submit Your CV</option>
                </select>
              </div>
              <div className="w-full lg:w-1/2 px-2">
                <p className="font_secondary">LinkedIn URL</p>
                <input
                  type="text"
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row my-2">
              <div className="w-full px-2">
                <p className="font_secondary">Any Comments</p>
                <textarea
                  type="text"
                  className="p-2 rounded-lg border-[1px] w-full border-main text-main bg-[white] focus:outline-0"
                />
              </div>
            </div>
            <div className="w-full px-2">
              <input
                type="submit"
                className="px-8 py-2 rounded-lg border-2 w-max cursor-pointer font_primary border-second hover:border-second font_secondary text-[white] hover:text-second bg-second hover:bg-[white] focus:outline-0"
              />
            </div>
          </form>
        </div>
      </section> */}
      <Footern />
    </>
  );
}

export default WeAreHiring;